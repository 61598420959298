.contacts{
    position: relative;
    height: 100%;
   &__text{
       position: absolute;
       bottom: 0;
       right: 0;
       padding: 30px;
       font-size: 2rem;
   }
}
