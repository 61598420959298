@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

html{
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
  font-weight: 100;
}
.layout{
  background-image: url('./assets/foto.jpg');
  background-position:top;
  background-position-y: 20%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: white;
  background-color: rgb(22, 65, 148)
}

.mobile{
  display: none;
}

@media screen and (max-width: 740px) {
  .mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    background-color:rgb(22, 65, 148);
    color: white;
    height: 100vh;
  }
  .img{
    width: 100vw;
    height: auto;
  }
  .layout{
    display: none;
  }
}

@media screen and (max-width: 380px) {
  html{
    font-size: 16px;
  }
}

@media screen and (max-width: 340px) {
  html{
    font-size: 14px;
  }
}